<template>
  <div class="data-box">
    <van-nav-bar
        title="物资详情"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <div class="span-key">物资名称</div>
          <div class="span-input" @click="editMaterialItem">
            <span>{{ materialName }}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
        <div class="body-div-item" v-for="(item,index) in materialList" :key="index">
          <div class="span-key span-line">{{ item.key }}</div>
          <div class="span-value">{{ item.value }}</div>
        </div>
        <div class="body-div-item">
          <div class="span-key span-line">使用说明</div>
          <div class="span-value">{{ remark ? remark : "无" }}</div>
        </div>
        <div class="body-div-item" v-show="itemList.length > 0">
          <div class="span-key span-line">物品图片</div>
          <div class="span-image">
            <van-image class="image" fit="cover" round v-for="(item,index) in itemList" :key="index" :src="item" @click="showImage(index,itemList)"/>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="showMaterialItem" :actions="materialActions" @select="selectMaterialItem"/>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {ImagePreview} from "vant";
import {
  h5MaterialDetail,
  showActivityMaterial
} from "@/api/index";

export default {
  name: "orgCheck_material_details",
  data() {
    return {
      materialName: "",
      materialList: [
        { key: "物资数量", value: ""},
        { key: "派发数量", value: ""},
        { key: "剩余数量", value: ""}
      ],
      remark: "",
      itemList: [],
      showMaterialItem: false,
      materialActions: []
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      that.getMaterialList(that.$route.query.ayId);
      that.getMaterialInfo(that.$route.query.id);
    },
    getMaterialList(ayId){
      let that = this;
      let params = {
        ayId : ayId ? ayId : that.$store.getters.getSelectMaterialPersonId
      };
      showActivityMaterial(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.materialActions = res.data.materialList;
          that.materialActions.forEach(item => {
            item.name = item.str;
          })
        })
      })
    },
    getMaterialInfo(id){
      let that = this;
      let params = {materialType: id};
      h5MaterialDetail(params).then(res => {
        that.$tool.resValidation(res, () => {
          let data = res.data;
          that.materialName = data.name;
          that.materialList[0].value = data.num1 + data.unit;
          that.materialList[1].value = Math.abs(data.num2) + data.unit;
          that.materialList[2].value = data.num3 + data.unit;
          that.remark = data.str1;
          that.itemList = data.str2.split(",");
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    editMaterialItem(){
      let that = this;
      that.showMaterialItem = true;
    },
    selectMaterialItem(item) {
      let that = this;
      that.getMaterialInfo(item.id);
      that.showMaterialItem = false;
    },
    showImage(index,array){
      ImagePreview({
        images: array,
        startPosition: index,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>